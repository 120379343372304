<template>
    <ui-create
        name="address"
        :title="title"
        :load="load"
    >
        <template #body >
            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-autocomplete
                            label="Nome"
                            v-model="form.id_pessoa"
                            :items="sectionsPeople"
                            item-text="nome"
                            item-value="id"
                            :rules="[form_pessoa_rules.required]"
                        ></v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="CEP"
                            v-model="form.cep"
                            maxlength="9"
                            v-mask="'#####-###'"
                            @keyup="onKeyup"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Rua"
                            v-model="form.endereco"
                            counter
                            maxlength="128"
                            :rules="[
                                form_endereco_rules.required, form_endereco_rules.counter
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Nº"
                            v-model="form.numero"
                            maxlength="5"
                            v-mask="'#####'"
                            :rules="[form_numero_rules.required, form_numero_rules.nan]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Bairro"
                            v-model="form.bairro"
                            counter
                            maxlength="64"
                            :rules="[
                                form_bairro_rules.required, form_bairro_rules.counter
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-autocomplete
                            label="Cidade"
                            v-model="form.id_cidade"
                            :items="sectionsCity"
                            item-text="descricao"
                            item-value="id"
                            :rules="[form_cidade_rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwaddressCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                id_pessoa: null,
                cep: '',
                endereco: '',
                numero: '',
                bairro: '',
                id_cidade: null
            },

            sectionsPeople: [],

            sectionsCity: [],

            form_pessoa_rules: {
                required: value => !!value || 'Nome é obrigatório.'
            },

            form_endereco_rules: {
                required: value => !!value || 'Rua é obrigatória.',
                counter: value => value.length <= 128 || 'Dados inválido!'
            },

            form_numero_rules: {
                required: value => !!value || 'Numero é obrigatório.',
                nan: value => !isNaN(value) || 'Dados incorreto!'
            },

            form_bairro_rules: {
                required: value => !!value || 'Bairro é obrigatório.',
                counter: value => value.length <= 64 || 'Dados inválido!'
            },

            form_cidade_rules: {
                required: value => !!value || 'Selecione uma cidade.'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar empresa: ${this.id}` : 'Cadastrar Empresa'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },

        onKeyup: function() {
            if ( this.form.cep.length == 9 ) {
            this.overlay = true
			fetch('https://viacep.com.br/ws/'+this.form.cep+'/json/', {method: 'get'})
                .then(response => (response.json()))
                .then(responseJson => {
                    this.form.endereco = responseJson.logradouro
                    this.form.bairro = responseJson.bairro
                    this.form.cidade = responseJson.localidade
                })
                .catch(error => {
					console.error('Error', '=>', error)
				})
				.finally(() => {
					this.overlay = false
				})
            }
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/address/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-address', 'Cadastro atualizado!')
                    this.getData()
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-address', 'Erro interno ao atualizar a empresa!')
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/address/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-address', 'Empresa cadastrada com sucesso!')
                    this.clearForm()
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-address', 'Erro interno ao gravar a empresa!')
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/address/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-address', error.message)
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getSectionsCity: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/cidades', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsCity = resJSON.response
                })
                .catch((error) => {
                    this.$root.$emit('open-snackbar-on-address', error.message)
                })
        },

        getSectionsPeople: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/pessoas/id,nome', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsPeople = resJSON.response
                })
                .catch((error) => {
                    this.$root.$emit('open-snackbar-on-address', error.message)
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.getSectionsCity()
        this.getSectionsPeople()

        this.$root.$on('save-changes-on-address', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>